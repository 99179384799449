
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
  direction: ltr;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

p {
  line-height: 25px;
}

.row img {
  height: auto;
  max-width: 100%;
}

.spacer {
  margin-left: 2.5em;
}

.align-top {

  flex-wrap: wrap;

}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.container {

  border: 2px solid rgb(15, 13, 15);
  border-radius: .5em;
  padding: 4x;
  justify-content: space-between;
  align-content: space-between;
}

.item {
  padding: 10px;
  background-color: rgba(111,41,97,.3);
  border: 2px solid rgba(111,41,97,.5);
}

a {
  text-decoration: none;
  line-height: inherit;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

iframe {
  border: 0 !important;
}

.parallax-window {
  min-height: 400px;
  background: transparent;
}

figure {
  margin: 0;
}


/* You can add global styles to this file, and also import other style files */



@import '../node_modules/ngx-red-zoom/styles/base';
@import '../node_modules/ngx-red-zoom/styles/style-magnifier';
@import '../node_modules/ngx-red-zoom/styles/style-window';
@import '../node_modules/ngx-red-zoom/styles/style-overlay';

.red-zoom__lens-image {
  --red-zoom-min-scale-factor: 1;
  --red-zoom-max-scale-factor: 6;

  min-width: calc(var(--red-zoom-thumb-w) * var(--red-zoom-min-scale-factor));
  min-height: calc(var(--red-zoom-thumb-h) * var(--red-zoom-min-scale-factor));
  max-width: calc(var(--red-zoom-thumb-w) * var(--red-zoom-max-scale-factor));
  max-height: calc(var(--red-zoom-thumb-h) * var(--red-zoom-max-scale-factor));
}


/* Page Border */

.page-border {
  position: fixed;
  z-index: 999999;
  pointer-events: none;
}

.page-border .bottom-border,
.page-border .left-border,
.page-border .right-border,
.page-border .top-border {
  background: #f3f3ef;
  position: fixed;
  z-index: 9999;
}

.page-border > .top-border,
.page-border > .right-border,
.page-border > .bottom-border,
.page-border > .left-border {
  padding: 11px;
  background: #ccc;
}

.page-border .bottom-border,
.page-border .top-border {
  width: 100%;
  padding: 10px;
  left: 0;
}

.page-border .left-border,
.page-border .right-border {
  padding: 10px;
  height: 100%;
  top: 0;
}

.page-border .top-border {
  top: 0;
}

.page-border .right-border {
  right: 0;
}

.page-border .bottom-border {
  bottom: 0;
}

.page-border .left-border {
  left: 0;
}

#wrapper {
  margin: 0 15px;
  padding: 15px 0;
  position: relative;
}

/* --------- 1.1 Input Elements ---------- */

input,
textarea {
  border: 1px solid #e1e1e1;
  padding: 10px;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  transition: all 0.3s linear;
  border-radius: 3px;
}

input {
  height: 40px;
  margin: 3px 0;
  outline: none;
  line-height: normal;
  font-size: 14px;
}

input[type="submit"] {
  cursor: pointer;
  border-style: solid;
  border-width: 2px;
  padding-top: 0;
  padding-bottom: 0;
}

select {
  border: 1px solid #e1e1e1;
  height: 40px;
  padding: 5px;
}

input:focus,
textarea:focus {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

*:focus {
  outline: none;
}

/*------------------------------------------------------------------------------------------*/
/* 2. Layout Elements */
/*------------------------------------------------------------------------------------------*/

.col-33,
.row {
  width: 100%;
  padding-right: 0;
  padding-left: 20px;
}


section {
  clear: both;
  overflow: hidden;
}

/* Rows and Columns */

.row {
  max-width: 1245px;
  margin: 0 auto;
  padding: 75px 0;
  position: relative;
}

.no-padding-bottom .row,
.no-padding-bottom div,
.no-padding-bottom.row {
  padding-bottom: 0;
}

.no-padding-top.row,
.no-padding-top div {
  padding-top: 0;
}

.big-padding-top {
  padding-top: 75px !important;
}

.big-padding-bottom {
  padding-bottom: 85px !important;
}

/* Targets all elements */

[class*="col-"] {
  float: left;
  padding: 20px;
}

#clients .col-2-3 [class*="col-"] {
  padding: 0;
}

/* Clearfix */

.clearfix:after {
  content: "";
  display: table;
  clear: both;
  background-color: #202020;
}

/* Main Widths */

.col-1 {
  width: 100%;
  /* padding: 20px !important; */
}

.col-2 {
  width: 50%;
}

.col-3 {
  width: 33.33%;
}

.col-4 {
  width: 25%;
}

.col-5 {
  width: 20%;
}

.col-6 {
  width: 16.6666666667%;
}

.col-7 {
  width: 14.2857142857%;
}

.col-8 {
  width: 12.5%;
}

.col-9 {
  width: 11.1111111111%;
}

.col-10 {
  width: 10%;
}

.col-11 {
  width: 9.09090909091%;
}

.col-12 {
  width: 8.33%;
}

.col-2-3 {
  width: 66.66%;
}

.col-3-4 {
  width: 75%;
}

.col-9-10 {
  width: 90%;
}

/* Golden Ratio */
.col-61 {
  width: 61.8%;
}

.col-38 {
  width: 38.2%;
  padding: 20px !important;
}

.col-33 {
  width: 20%;
}

/* --------- 2.1 Header --------- */

#header {
  height: 71px !important;
  overflow: visible;

  z-index: 9999;
  width: 100%;
  position: absolute !important;
}

#header .row {
  padding: 0;
}

/* #header aside {
  text-align: right;
} */

#header ul {
  text-align: center;
}

#header li {
  display: inline-block;
  list-style: none;
  margin: 0;
}


#logo {
  float: left;
  height: 71px;
  line-height: 66px;
  margin-right: 15px;
}

#logo h1,
#logo h2 {
  display: inline-block;
}

#banner #logo h1 {
  font-size: 28px;
  margin-right: 10px;
  font-weight: 900;
  padding: 0;
}

#logo h2 {
  font-size: 18px;
  padding: 0;
}

#logo img {

  vertical-align: middle;
  margin-right: 15px;
}

#navigation-logo {
  display: none;
}

.nav-solid #logo #banner-logo {
  display: none;
  height: 71px;
}

.nav-solid #logo #navigation-logo {
  display: inline-block;

}

/* --------- 2.3 Buttons ---------- */

.call-to-action {
  padding: 35px 0 35px 0;
}

/*Style*/

.button {
  font-size: 16px;
  margin: 35px 0;
  padding: 11px 16px;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  transition: all 0.3s linear;
  display: inline-block;
  border-width: 3px;
  border-style: solid;
}

/* Play Button */

#video-section {
  position: relative;
}

.play-video {
  height: 110px;
  position: absolute;
  top: 50%;
  margin-top: -110px;
  width: 100%;
}

.play-icon {
  display: inline-block;
  font-size: 0px;
  cursor: pointer;
  margin: 45px auto;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  text-align: center;
  position: relative;
  z-index: 1;
}

.play-icon:after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: "";
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

/* --------- 2.4 Navigation ---------- */

#header {
  font-size: 13px;

}

#header aside {
  float: right;
}

#header nav ul {
  text-transform: uppercase;
}

#header nav a {
  height: 71px;
  line-height: 71px;
  display: block;
  padding: 0 10px;
}

#header nav a:hover {
  opacity: 0.6;
}

/*Navigation Solid*/
#header.nav-solid [class*="col-"] {
  padding: 0 20px;
    background-color: #000000;
}

#header.nav-solid {
  background: #fff;
  box-shadow: 2px 0px 3px rgba(0, 0, 0, 0.2);
  position: fixed !important;
  left: 0;
}
#header.nav-solid nav a {
  border-bottom: 3px solid;
  border-color: #fff;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

#header.nav-solid nav a:hover {
  opacity: 1;

}

/* Social Elements when Solid*/

#header.nav-solid .social-icons a {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  opacity: 0.5;
}

#header.nav-solid .social-icons a:hover {
  opacity: 1;
  color: #e4473c;
}

/* Responsive Nav Styling */

#nav-trigger {
  display: none;
  text-align: right;
}

#nav-trigger span {
  display: inline-block;
  width: 38px;
  height: 71px;
  color: #000;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 22px;
  text-align: center;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

#nav-trigger span:after {
  font-family: "fontAwesome";
  display: inline-block;
  width: 38px;
  height: 71px;
  line-height: 75px;
  text-align: center;
  content: "\f0c9";
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

#nav-trigger span.open:after {
  content: "\f00d";
}

#nav-trigger span:hover,
.nav-solid #nav-trigger span.open:hover,
.nav-solid #nav-trigger span:hover {
  opacity: 0.6;
}

#nav-trigger span.open,
#nav-trigger span.open:hover {
  color: #000;
}

.nav-solid #nav-trigger span.open:hover {
  color: #999;
}

.nav-solid #nav-trigger span {
  color: #999;
  opacity: 1;
}

nav#nav-mobile {
  position: relative;
  display: none;
}

nav#nav-mobile ul {
  display: block;
  list-style-type: none;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: -20px;
  margin-right: -20px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  background-color: #000000;
  box-shadow: 0 5px 3px rgba(0, 0, 0, 0.2);
}

nav#nav-mobile ul:after {
  display: none;
}

nav#nav-mobile li {
  margin: 0 20px;
  float: none;
  text-align: left;
  border-bottom: 1px solid #e1e1e1;
}

nav#nav-mobile li:last-child {
  border-bottom: none;
}

.nav-solid nav#nav-mobile li {
  border-top: 1px solid #e1e1e1;
  border-bottom: none;
}

nav#nav-mobile a {
  display: block;
  padding: 12px 0;
  color: #333;
  width: 100%;
  height: auto;
  line-height: normal;
  display: block;
  border-bottom: none !important;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

nav#nav-mobile a:hover {
  background: #9c9c9c;
  opacity: 1;
}

/* ---------  Social Elements ---------- */

#header .col-4 {
  text-align: right;
}

.coinTypeBlock {
  height: 150px;

}

.social-icons a {
  display: inline-block;
  width: 32px;
  text-align: center;
}

.social-icons a:hover {
  opacity: 0.7;
}

.social-icons span {
  display: none;
}

#header .social-icons {
  margin-top: 27px;
}

/* --------- 2.6 Images ---------- */

/*Alignment*/

img {
  vertical-align: top;
}

a img {
  border: none;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

a img:hover {
  opacity: 0.7;
}

/* --------- 2.7 Positioning ---------- */

/*Alignment*/

.pull-right {
  padding-left: 15px;
}

.pull-left {
  padding-right: 15px;
}

.pull-down {
  padding-top: 30px;
}

/*------------------------------------------------------------------------------------------*/
/* 3. Fonts */
/*------------------------------------------------------------------------------------------*/

h1 {
  padding: 20px 0;
}

h2 {
  padding: 14px 0;
}

h3 {
  padding: 10px 0 0 15px;
}

h4 {
  padding: 7px 0;
}

/* Text Alignment */

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

/* Section Headings */

.section-heading {
  padding: 0;
}

.section-subtitle {
  font-size: 18px;
  padding-top: 0;
}

.section-heading h3 {
  font-size: 14px;
  font-weight: bold;
  color: #ccc;
  letter-spacing: 2px;
  padding-bottom: 0;
}

/*------------------------------------------------------------------------------------------*/
/* 4. Banner */
/*------------------------------------------------------------------------------------------*/

#banner {
  background-size: cover;
}

#banner-content.row {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 50px;
}

#banner h1 {
  padding-top: 5%;
}

#banner .section-heading:before {
  content: "";
  display: block;
  width: 30px;
  height: 5px;
  margin-top: 30px;
  margin-left: 15px;
}

/*------------------------------------------------------------------------------------------*/
/*  Content  */
/*------------------------------------------------------------------------------------------*/

/* --------- Icons ---------- */

/*Font Icon sizes*/

.fa-1x {
  font-size: 15px !important;
}

/*Icon Block*/

.icon-block {
  position: relative;
}

.icon-block h4 {
  font-weight: bold;
  padding-top: 0;
}

.icon-block .icon {
  position: absolute;
}

.icon-block p {
  margin-top: 0;
}

/* Icon Left*/

.icon-left .icon {
  left: 15;
}

.icon-left .icon-block-description {
  padding-left: 53px;
}

/* Icon Right */

.icon-right .icon {
  right: 15;
}

.icon-right .icon-block-description {
  padding-right: 53px;
}

/* Icon Above */

.icon-top {
  display: block;
}

.icon-top .icon {
  position: relative;
  display: block;
}

.icon-top .icon-block-description {
  padding-top: 25px;
}

/* ---------  Parallax  ---------- */

/* ---------  Divider ---------- */

.divider {
  position: relative;
  width: 40%;
  height: 20px;
  line-height: 20px;
  margin: 10px auto 5px auto;
  clear: both;
  text-align: center;
}

.divider i {
  background: #fff;
  position: relative;
  top: -11px;
  padding: 0 5px 0 5px;
  color: #e1e1e1;
}

/*------------------------------------------------------------------------------------------*/
/* Home Page  */
/*------------------------------------------------------------------------------------------*/

/* ---------  Introduction ---------- */

.introduction img.featured {
  padding: 55px 0 0 0;
}

/* --------- Features ---------- */

.features div:nth-child(4) {
  clear: both;
}

/* --------- coin ---------- */
.coin {
  background: #fff;
  padding: 25px 0 25px 0;
  margin: 0 15px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
  transition: all 0.2s ease-in-out 0s;
  border: 10px solid #fff;
}


.monarch {
  background: #fff;
  padding: 25px 0 25px 0;
  margin: 0 15px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
  transition: all 0.2s ease-in-out 0s;
  border: 10px solid #fff;
}

.coin footer {
  padding-top: 12px;
}

/* Style Classic - Avatar above coin */

.coin.classic img {
  display: inline-block;
  margin-bottom: 25px;
}

.coin.classic q {
  display: block;
}

.coin.classic footer:before {
  display: block;
  content: "";
  width: 80px;
  height: 4px;
  margin: 10px auto 15px auto;
}

/* ---------  Testimonials ---------- */

.testimonial {
  padding: 15px;
}

blockquote {
  position: relative;
}

.testimonial img {
  max-height: 120px;
  border-radius: 250em;
}

.testimonial footer {
  padding-top: 12px;
}

/* Style Classic - Avatar above Testimonial */

.testimonial.classic img {
  display: inline-block;
  margin-bottom: 25px;
}

.testimonial.classic q {
  display: block;
}

.testimonial.classic footer:before {
  display: block;
  content: "";
  width: 30px;
  height: 4px;
  margin: 10px auto 15px auto;
}

/* Style Big */

/* ---------  Gallery ---------- */

#gallery img {
  max-height: 300px;
}

#gallery [class*="col-"] {
  padding: 0;
}

#gallery a {
  display: inline-block;
  position: relative;
  overflow: hidden;
}

#gallery a:hover img {
  opacity: 0.15;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

#gallery a:before {
  font-size: 2.2em;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  content: "+";
  width: 70px;
  height: 70px;
  border-radius: 100em;
  display: block;
  position: absolute;
  top: 55%;
  left: 50%;
  line-height: 72px;
  margin: -35px 0 0 -35px;
  opacity: 0;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  transition: all 0.2s linear;
  /* vertical-align: middle; */
}

#gallery a:hover:before {
  opacity: 1;
  top: 50%;
  left: 50%;
}

.featherlight iframe {
  /* styling */
  border: none;
}


/* Always display arrows on touch devices */
@media only screen and (max-device-width: 1024px) {
  .featherlight-next:hover,
  .featherlight-previous:hover {
    background: none;
  }

  .featherlight-next span,
  .featherlight-previous span {
    display: block;
  }
}

/* ---------  Video ---------- */

.video_container,
.easyContainer {
  position: relative;
  padding-bottom: 56.25%; /* 16/9 ratio */
  margin: 0;
  padding-top: 0; /* IE6 workaround*/
  height: 0;
  overflow: hidden;
}

.video_container iframe,
.video_container object,
.video_container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.slvj-lightbox iframe,
.slvj-lightbox object,
.slvj-lightbox embed {
  width: 100% !important;
  height: 529px !important;
}

#slvj-window {
  width: 100%;
  min-height: 100%;
  z-index: 9999999999;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
}

#slvj-window,
#slvj-window * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#slvj-background-close {
  width: 100%;
  min-height: 100%;
  z-index: 31;
  position: fixed;
  top: 0;
  left: 0;
}

#slvj-back-lightbox {
  max-width: 940px;
  max-height: 529px;
  z-index: 32;
  text-align: center;
  margin: 0 auto;
}

.slvj-lightbox {
  width: 100%;
  height: 100%;
  margin: 0;
  z-index: 53;
}

#slvj-close-icon:before {
  font-family: "fontawesome";
  color: #fff;
  content: "\f00d";
  font-size: 22px;
  opacity: 0.5;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  z-index: 99999;
  height: 22px;
  width: 22px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}


/* --------- crowns ---------- */

#crowns a {
  display: block;

  position: relative;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
}

#crowns img {
  display: inline-block;

}

.crown-overlay {
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

#crowns a:hover .crown-overlay {
  opacity: 1;
}

.crown-overlay span {
  position: absolute;
  top: 48%;
  left: 0;
  width: 100%;
  text-align: center;
  display: inline-block;
}


/* ---------  country-overlay ---------- */


.country-overlay {
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

#crowns a:hover .country-overlay {
  opacity: 1;
}

.country-overlay span {
  position: absolute;
  top: 48%;
  left: 0;
  width: 100%;
  text-align: center;
  display: inline-block;
}


/* ---------  Clients ---------- */

#clients a {
  display: block;
  text-align: center;
  position: relative;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
}

#clients img {
  display: inline-block;
  padding: 15px 25px;
}

.client-overlay {
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

#clients a:hover .client-overlay {
  opacity: 1;
}

.client-overlay span {
  position: absolute;
  top: 48%;
  left: 0;
  width: 100%;
  text-align: center;
  display: inline-block;
}

/* --------- 6.7 Pricing Table ---------- */

.pricing-block-content {
  background: #fff;
  padding: 25px 0 25px 0;
  margin: 0 15px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
  transition: all 0.2s ease-in-out 0s;
  position: relative;
  border: 10px solid #fff;
}

.pricing-block ul {
  list-style: none;
  margin: 25px 0 25px 0;
}

.pricing-block li {
  padding: 14px 0;
  border-bottom: 1px dotted #e1e1e1;
}

.pricing-block li:last-of-type {
  border: none;
}

.pricing-block h3 {
  font-size: 17px;
  text-transform: uppercase;
  padding-bottom: 0;
}

.pricing-sub {
  font-style: italic;
  color: #ccc;
  margin: 0 0 25px 0;
}

/*Price*/

.pricing {
  background: #f5f5f5;
  padding: 25px;
  position: relative;
}

.price {
  display: inline-block;
  font-size: 62px;
  font-weight: 700;
  position: relative;
}

.price span {
  position: absolute;
  top: 0;
  left: -20px;
  font-size: 22px;
}

.pricing p {
  font-style: italic;
  color: #b4b4b4;
  line-height: auto;
  margin: 0;
}

/*------------------------------------------------------------------------------------------*/
/* 7. Footer */
/*------------------------------------------------------------------------------------------*/

#landing-footer {
  clear: both;
}

#landing-footer .row {
  padding: 0;
}

#landing-footer p {
  margin: 0;
  padding-left: 35px;
}

#landing-footer ul {
  list-style: none;
  text-align: right;
}

#landing-footer li {
  display: inline-block;
}

/* Scroll Up */

#scrollUp {
  bottom: 15px;
  right: 30px;
  width: 70px;
  height: 80px;
  margin-bottom: -10px;
  padding: 0 5px 20px 5px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  -webkit-transition: margin-bottom 150ms linear;
  -moz-transition: margin-bottom 150ms linear;
  -o-transition: margin-bottom 150ms linear;
  transition: margin-bottom 150ms linear;
}

#scrollUp:hover {
  margin-bottom: 0;
}

#scrollUp:before {
  background: #ccc;
  font-family: "fontawesome";
  font-size: 2.4em;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  color: #fff;
  width: 45px;
  height: 45px;
  display: inline-block;
  line-height: 45px;
  content: "\f106";
  opacity: 1;
}

/*Preloader*/

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff; /* change if the mask should have another color then white */
  z-index: 99999; /* makes sure it stays on top */
}

#status {
  width: 110px;
  height: 110px;
  position: absolute;
  left: 50%; /* centers the loading animation horizontally one the screen */
  top: 50%; /* centers the loading animation vertically one the screen */
  background-position: center;
  margin: -55px 0 0 -55px; /* is width and height divided by two */
  text-align: center;
}

/*!
* Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
* Copyright 2015 Daniel Cardoso <@DanielCardoso>
* Licensed under MIT
*/
.la-ball-triangle-path,
.la-ball-triangle-path > div {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.la-ball-triangle-path {
  display: block;
  font-size: 0;
  color: #fff;
}

.la-ball-triangle-path.la-dark {
  color: #333;
}

.la-ball-triangle-path > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}

.la-ball-triangle-path {
  width: 32px;
  height: 32px;
}

.la-ball-triangle-path > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.la-ball-triangle-path > div:nth-child(1) {
  -webkit-animation: ball-triangle-path-ball-one 2s 0s ease-in-out infinite;
  -moz-animation: ball-triangle-path-ball-one 2s 0s ease-in-out infinite;
  -o-animation: ball-triangle-path-ball-one 2s 0s ease-in-out infinite;
  animation: ball-triangle-path-ball-one 2s 0s ease-in-out infinite;
}

.la-ball-triangle-path > div:nth-child(2) {
  -webkit-animation: ball-triangle-path-ball-two 2s 0s ease-in-out infinite;
  -moz-animation: ball-triangle-path-ball-two 2s 0s ease-in-out infinite;
  -o-animation: ball-triangle-path-ball-two 2s 0s ease-in-out infinite;
  animation: ball-triangle-path-ball-two 2s 0s ease-in-out infinite;
}

.la-ball-triangle-path > div:nth-child(3) {
  -webkit-animation: ball-triangle-path-ball-tree 2s 0s ease-in-out infinite;
  -moz-animation: ball-triangle-path-ball-tree 2s 0s ease-in-out infinite;
  -o-animation: ball-triangle-path-ball-tree 2s 0s ease-in-out infinite;
  animation: ball-triangle-path-ball-tree 2s 0s ease-in-out infinite;
}

.la-ball-triangle-path.la-sm {
  width: 16px;
  height: 16px;
}

.la-ball-triangle-path.la-sm > div {
  width: 4px;
  height: 4px;
}

.la-ball-triangle-path.la-2x {
  width: 64px;
  height: 64px;
}

.la-ball-triangle-path.la-2x > div {
  width: 20px;
  height: 20px;
}

.la-ball-triangle-path.la-3x {
  width: 96px;
  height: 96px;
}

.la-ball-triangle-path.la-3x > div {
  width: 30px;
  height: 30px;
}

/*
* Animations
*/
@-webkit-keyframes ball-triangle-path-ball-one {
  0% {
    -webkit-transform: translate(0, 220%);
    transform: translate(0, 220%);
  }
  17% {
    opacity: 0.25;
  }
  33% {
    opacity: 1;
    -webkit-transform: translate(110%, 0);
    transform: translate(110%, 0);
  }
  50% {
    opacity: 0.25;
  }
  66% {
    opacity: 1;
    -webkit-transform: translate(220%, 220%);
    transform: translate(220%, 220%);
  }
  83% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 220%);
    transform: translate(0, 220%);
  }
}

@-moz-keyframes ball-triangle-path-ball-one {
  0% {
    -moz-transform: translate(0, 220%);
    transform: translate(0, 220%);
  }
  17% {
    opacity: 0.25;
  }
  33% {
    opacity: 1;
    -moz-transform: translate(110%, 0);
    transform: translate(110%, 0);
  }
  50% {
    opacity: 0.25;
  }
  66% {
    opacity: 1;
    -moz-transform: translate(220%, 220%);
    transform: translate(220%, 220%);
  }
  83% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
    -moz-transform: translate(0, 220%);
    transform: translate(0, 220%);
  }
}

@-o-keyframes ball-triangle-path-ball-one {
  0% {
    -o-transform: translate(0, 220%);
    transform: translate(0, 220%);
  }
  17% {
    opacity: 0.25;
  }
  33% {
    opacity: 1;
    -o-transform: translate(110%, 0);
    transform: translate(110%, 0);
  }
  50% {
    opacity: 0.25;
  }
  66% {
    opacity: 1;
    -o-transform: translate(220%, 220%);
    transform: translate(220%, 220%);
  }
  83% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
    -o-transform: translate(0, 220%);
    transform: translate(0, 220%);
  }
}

@keyframes ball-triangle-path-ball-one {
  0% {
    -webkit-transform: translate(0, 220%);
    -moz-transform: translate(0, 220%);
    -o-transform: translate(0, 220%);
    transform: translate(0, 220%);
  }
  17% {
    opacity: 0.25;
  }
  33% {
    opacity: 1;
    -webkit-transform: translate(110%, 0);
    -moz-transform: translate(110%, 0);
    -o-transform: translate(110%, 0);
    transform: translate(110%, 0);
  }
  50% {
    opacity: 0.25;
  }
  66% {
    opacity: 1;
    -webkit-transform: translate(220%, 220%);
    -moz-transform: translate(220%, 220%);
    -o-transform: translate(220%, 220%);
    transform: translate(220%, 220%);
  }
  83% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 220%);
    -moz-transform: translate(0, 220%);
    -o-transform: translate(0, 220%);
    transform: translate(0, 220%);
  }
}

@-webkit-keyframes ball-triangle-path-ball-two {
  0% {
    -webkit-transform: translate(110%, 0);
    transform: translate(110%, 0);
  }
  17% {
    opacity: 0.25;
  }
  33% {
    opacity: 1;
    -webkit-transform: translate(220%, 220%);
    transform: translate(220%, 220%);
  }
  50% {
    opacity: 0.25;
  }
  66% {
    opacity: 1;
    -webkit-transform: translate(0, 220%);
    transform: translate(0, 220%);
  }
  83% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(110%, 0);
    transform: translate(110%, 0);
  }
}

@-moz-keyframes ball-triangle-path-ball-two {
  0% {
    -moz-transform: translate(110%, 0);
    transform: translate(110%, 0);
  }
  17% {
    opacity: 0.25;
  }
  33% {
    opacity: 1;
    -moz-transform: translate(220%, 220%);
    transform: translate(220%, 220%);
  }
  50% {
    opacity: 0.25;
  }
  66% {
    opacity: 1;
    -moz-transform: translate(0, 220%);
    transform: translate(0, 220%);
  }
  83% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
    -moz-transform: translate(110%, 0);
    transform: translate(110%, 0);
  }
}

@-o-keyframes ball-triangle-path-ball-two {
  0% {
    -o-transform: translate(110%, 0);
    transform: translate(110%, 0);
  }
  17% {
    opacity: 0.25;
  }
  33% {
    opacity: 1;
    -o-transform: translate(220%, 220%);
    transform: translate(220%, 220%);
  }
  50% {
    opacity: 0.25;
  }
  66% {
    opacity: 1;
    -o-transform: translate(0, 220%);
    transform: translate(0, 220%);
  }
  83% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
    -o-transform: translate(110%, 0);
    transform: translate(110%, 0);
  }
}

@keyframes ball-triangle-path-ball-two {
  0% {
    -webkit-transform: translate(110%, 0);
    -moz-transform: translate(110%, 0);
    -o-transform: translate(110%, 0);
    transform: translate(110%, 0);
  }
  17% {
    opacity: 0.25;
  }
  33% {
    opacity: 1;
    -webkit-transform: translate(220%, 220%);
    -moz-transform: translate(220%, 220%);
    -o-transform: translate(220%, 220%);
    transform: translate(220%, 220%);
  }
  50% {
    opacity: 0.25;
  }
  66% {
    opacity: 1;
    -webkit-transform: translate(0, 220%);
    -moz-transform: translate(0, 220%);
    -o-transform: translate(0, 220%);
    transform: translate(0, 220%);
  }
  83% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(110%, 0);
    -moz-transform: translate(110%, 0);
    -o-transform: translate(110%, 0);
    transform: translate(110%, 0);
  }
}

@-webkit-keyframes ball-triangle-path-ball-tree {
  0% {
    -webkit-transform: translate(220%, 220%);
    transform: translate(220%, 220%);
  }
  17% {
    opacity: 0.25;
  }
  33% {
    opacity: 1;
    -webkit-transform: translate(0, 220%);
    transform: translate(0, 220%);
  }
  50% {
    opacity: 0.25;
  }
  66% {
    opacity: 1;
    -webkit-transform: translate(110%, 0);
    transform: translate(110%, 0);
  }
  83% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(220%, 220%);
    transform: translate(220%, 220%);
  }
}

@-moz-keyframes ball-triangle-path-ball-tree {
  0% {
    -moz-transform: translate(220%, 220%);
    transform: translate(220%, 220%);
  }
  17% {
    opacity: 0.25;
  }
  33% {
    opacity: 1;
    -moz-transform: translate(0, 220%);
    transform: translate(0, 220%);
  }
  50% {
    opacity: 0.25;
  }
  66% {
    opacity: 1;
    -moz-transform: translate(110%, 0);
    transform: translate(110%, 0);
  }
  83% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
    -moz-transform: translate(220%, 220%);
    transform: translate(220%, 220%);
  }
}

@-o-keyframes ball-triangle-path-ball-tree {
  0% {
    -o-transform: translate(220%, 220%);
    transform: translate(220%, 220%);
  }
  17% {
    opacity: 0.25;
  }
  33% {
    opacity: 1;
    -o-transform: translate(0, 220%);
    transform: translate(0, 220%);
  }
  50% {
    opacity: 0.25;
  }
  66% {
    opacity: 1;
    -o-transform: translate(110%, 0);
    transform: translate(110%, 0);
  }
  83% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
    -o-transform: translate(220%, 220%);
    transform: translate(220%, 220%);
  }
}

@keyframes ball-triangle-path-ball-tree {
  0% {
    -webkit-transform: translate(220%, 220%);
    -moz-transform: translate(220%, 220%);
    -o-transform: translate(220%, 220%);
    transform: translate(220%, 220%);
  }
  17% {
    opacity: 0.25;
  }
  33% {
    opacity: 1;
    -webkit-transform: translate(0, 220%);
    -moz-transform: translate(0, 220%);
    -o-transform: translate(0, 220%);
    transform: translate(0, 220%);
  }
  50% {
    opacity: 0.25;
  }
  66% {
    opacity: 1;
    -webkit-transform: translate(110%, 0);
    -moz-transform: translate(110%, 0);
    -o-transform: translate(110%, 0);
    transform: translate(110%, 0);
  }
  83% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(220%, 220%);
    -moz-transform: translate(220%, 220%);
    -o-transform: translate(220%, 220%);
    transform: translate(220%, 220%);
  }
}

/*------------------------------------------------------------------------------------------*/
/* Responsive Elements */
/*------------------------------------------------------------------------------------------*/

/* =Media Queries for Nav
===============================*/
@media all and (max-width: 1024px) {
  #nav-trigger {
    display: block;
  }

  nav#nav-main {
    display: none;
  }

  nav#nav-mobile {
    display: block;
  }

  nav#nav-mobile li {
    display: block;
  }

  #header aside {
    display: none;
  }
}

/* Landscape mobile & down
===============================*/
@media (max-width: 500px) {

#logo,
#logo img {
    margin: 0;
    padding: 0;
    height: 70px;
}
#navigation-logo  {
  display: none;
}

#banner-logo {
  display: none;
}

  #wrapper {
    margin: 0;
    padding: 0;
  }

  .page-border,
  #scrollUp,
  #scrollUp:before {
    display: none;
  }

  .row {
    padding: 15px !important;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col-2-3,
  .col-3-4,
  .col-9-10,
  .col-61,
  .col-38,
  .row {
    width: 100%;
  }


  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col-2-3,
  .col-3-4,
  .col-9-10,
  .col-61,
  .col-38 {
    padding-right: 0;
    padding-left: 0;
  }

  /*-----------------Header Elements-----------------*/
  #header .row {
    padding: 0 15px !important;

  }

  #header.nav-solid [class*="col-"] {
    padding: 0;
  }

  /*Logo*/
  #logo h2 {
    padding: 0;
  }

  /* Social */
  /* #header aside {
      clear: both;
      padding: 0;
  } */

  #header ul.social-icons {
    margin-top: 0;
  }

  #banner-content.row {
    padding-top: 85px !important;
  }

  /*-----------------Sections-----------------*/

  /* Call to Action*/
  .call-to-action {
    padding-bottom: 25px;
  }

  /* Video */
  .slvj-lightbox iframe,
  .slvj-lightbox object,
  .slvj-lightbox embed {
    height: 270px !important;
  }

  /* Footer */
  #landing-footer #copyright {
    float: left;
    width: 50%;
  }

  #landing-footer .social-icons {
    float: right;
    width: 50%;
  }

  /*-----------------Typography-----------------*/
  h1 {
    font-size: 38px;
  }

  #banner h1 {
    font-size: 48px;
  }

  /* Hide Elements */
  a#scrollUp {
    display: none !important;
  }
}

/* Mobile to Tablet Portrait
===============================*/
@media (min-width: 480px) and (max-width: 767px) {
  #wrapper {
    margin: 0;
    padding: 0;
  }


  .page-border,
  #scrollUp,
  #scrollUp:before {
    display: none;
  }

  .row {
    padding: 15px 0 !important;
  }

  .col-1,
  .col-2,
  .col-5,
  .col-7,
  .col-9,
  .col-11,
  .col-2-3,
  .col-3-4,
  .col-61,
  .col-38,
  .row {
    width: 100%;
  }

  .col-3,
  .col-4,
  .col-6,
  .col-8,
  .col-10,
  .col-12 {
    width: 50%;
  }



  /*-----------------Header Elements-----------------*/
  #header .row {
    padding: 0 !important;
  }

  #header aside {
    display: inline-block;
    position: absolute;
    top: 40px;
    right: 60px;
    padding-top: 3px;
    padding-right: 5px;
  }

  #header.nav-solid aside {
    top: 20px;
  }

  #header aside ul {
    margin-top: 0 !important;
    padding-top: 6px;
  }

  #banner-content.row {
    padding-top: 85px !important;
  }

  /*Navigation*/
  nav#nav-mobile ul {
    margin-left: -40px;
    margin-right: -40px;
    padding-left: 20px;
    padding-right: 20px;
  }

  /*-----------------Sections-----------------*/

  /* Video */
  .slvj-lightbox iframe,
  .slvj-lightbox object,
  .slvj-lightbox embed {
    height: 370px !important;
  }

  /* Footer */
  #landing-footer #copyright {
    float: left;
    width: 50%;
  }

  #landing-footer .social-icons {
    float: right;
    width: 50%;
  }
}

/* Landscape Tablet to Desktop
===============================*/
@media (min-width: 768px) and (max-width: 1024px) {
  #wrapper {
    margin: 0;
    padding: 0;
  }

  .page-border {
    display: none;
  }

  .big-padding-top {
    padding-top: 45px !important;
  }

  /*-----------------Header Elements-----------------*/
  #header aside {
    display: inline-block;
    position: absolute;
    top: 40px;
    right: 60px;
    padding-top: 3px;
    padding-right: 5px;
  }

  #header.nav-solid aside {
    top: 20px;
  }

  #header aside ul {
    margin-top: 0 !important;
    padding-top: 6px;
  }

  /*Navigation*/
  nav#nav-mobile ul {
    margin-left: -40px;
    margin-right: -40px;
    padding-left: 20px;
    padding-right: 20px;
  }

  /*-----------------Sections-----------------*/

  /* Video */
  .slvj-lightbox iframe,
  .slvj-lightbox object,
  .slvj-lightbox embed {
    height: 432px !important;
  }
}

/* gilliamworldcoins Landing Page Dynamic Style Index
1. Website Default Styling
2. Navigation
3. Primary and Secondary Colors
4. Banner
5. Typography
6. Buttons
7. Footer
/*------------------------------------------------------------------------------------------*/
/* 1. Website Default Styling */
/*------------------------------------------------------------------------------------------*/

body {
  background: #fff;
}

/* Default Link Color */

a,
.la-ball-triangle-path {
  color: #d2b356;
}

a:hover,
#header.nav-solid nav a:hover {
  color: #d2b356;
}

/* Default Icon Color */

.icon i {
  color: #d2b356;
}

/* Border Color */

#banner .section-heading:before,
.testimonial.classic footer:before {
  background: #d2b356;
}

.pricing-block-content:hover {
  border-color: #d2b356;
}

#banner .section-heading:before,
.coin.classic footer:before {
  background: #d2b356;
}

/*------------------------------------------------------------------------------------------*/
/* 2. Navigation */
/*------------------------------------------------------------------------------------------*/

/* Transparent Navigation Color on a Banner */

#header nav a,
#header i {
  color: #fff;
}

/* Navigation Colors when the Navigation is sticky and solid */

#header.nav-solid,
#header.nav-solid a,
#header.nav-solid i,
#nav-mobile ul li a {
  color: #fff;
}

/* Navigation Active State */

#header.nav-solid .active {
  color: #d2b356;
  border-color: #d2b356;
}

/*------------------------------------------------------------------------------------------*/
/* 3. Primary and Secondary Colors */
/*------------------------------------------------------------------------------------------*/

/* Primary Background and Text Colors */

.primary-color,
.featured .pricing {
  background-color: #d2b356;
}

.primary-color,
.primary-color .section-title,
.primary-color .section-subtitle,
.featured .pricing,
.featured .pricing p {
  color: #fff;
}

.section-heading h2:after {
  background: #d2b356;
  content: "";
  display: block;
  width: 30px;
  height: 5px;
  margin-top: 30px;
}

.text-center .section-heading h2:after {
  margin: 30px auto 25px auto;
}

/* Primary Icon Colors */

.primary-color .icon i,
.primary-color i {
  color: #fff;
}

/* Secondary Background and Text Colors */

.secondary-color {
  background-color: #f5f5f5;
}

/*------------------------------------------------------------------------------------------*/
/* 4. Banner */
/*------------------------------------------------------------------------------------------*/

/* Banner Background and Text Colors */

#banner {
  background: url("assets/images/banner-images/homepage.png") no-repeat
    right ;
  background-size:auto;
  background-position-x: 700px;

}

/*------------------------------------------------------------------------------------------*/
/* 5. Typography */
/*------------------------------------------------------------------------------------------*/

body {
  font-family: "Open Sans", sans-serif, Arial, Helvetica;
  font-size: 15px;
  font-weight: normal;
  color: #000;
}

/* Logo, if you are using Fonts as Logo and not image
#logo h1 {
  font-family:;
  font-size:;
  font-weight:;
  color:;
}
#logo h2 {
  font-family:;
  font-size:;
  font-weight:;
  color:;
}
*/

/* Banner Typography */

#banner h1 {
  font-family: "Open Sans", sans-serif, Arial, Helvetica;
  font-size: 62px;
  line-height: 60px;
  font-weight: 800;
  color: #000;
}

#banner h2 {
  font-family: "Open Sans", sans-serif, Arial, Helvetica;
  font-size: 18px;
  font-weight: 300;
  color: #000;
}

/* Section Title and Subtitle */

.section-title {
  font-family: "Open Sans", sans-serif, Arial, Helvetica;
  font-size: 34px;
  font-weight: 700;
  color: #111;
}

.section-subtitle {
  font-family: "Open Sans", sans-serif, Arial, Helvetica;
  font-size: 16px;
  font-weight: 300;
  color: #666666;
}

/* Testimonial */

.testimonial q {
  font-family: "Open Sans", sans-serif, Arial, Helvetica;
  font-size: 17px;
  font-weight: 300;
}

.testimonial.classic q,
.testimonial.classic footer {
  color: #111;
}

/* coin */

.coin q {
  font-family: "Open Sans", sans-serif, Arial, Helvetica;
  font-size: 17px;
  font-weight: 300;
}

.coin.classic q,
.coin.classic footer {
  color: #111;
}


/* Standard Headings h1-h6 */

h1 {
  font-family: "Open Sans", sans-serif, Arial, Helvetica;
  font-size: 40px;
  font-weight: 300;
  color: #111;
}

h2 {
  font-family: "Open Sans", sans-serif, Arial, Helvetica;
  font-size: 34px;
  font-weight: 300;
  color: #111;
}

h3 {
  font-family: "Open Sans", sans-serif, Arial, Helvetica;
  font-size: 30px;
  font-weight: 700;
  color: #111;
}

h4 {
  font-family: "Open Sans", sans-serif, Arial, Helvetica;
  font-size: 18px;
  font-weight: 400;
  color: #111;
}

/*------------------------------------------------------------------------------------------*/
/* 6. Buttons */
/*------------------------------------------------------------------------------------------*/

/* ----------Default Buttons---------- */

/* Button Text */

/* ----------Banner Buttons---------- */

/* Button Text */

#banner .button {
  font-family: "Open Sans", sans-serif, Arial, Helvetica;
  font-size: 16px;
  color: #111;
}

/* Button Color */

#banner .button {
  border-color: #111;
}

/* Button Hover Color */

#banner .button:hover {
  color: #d2b356;
  border-color: #d2b356;
}

/*------------------------------------------------------------------------------------------*/
/* 7. Footer */
/*------------------------------------------------------------------------------------------*/

#landing-footer,
#landing-footer p,
#landing-footer a {
  font-family: "Open Sans", sans-serif, Arial, Helvetica;
  font-size: 12px;
  font-weight: normal;
  color: #999;
}

/* Footer Icon Color */

#landing-footer i {
  color: #999;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
